<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <base-card>
        <v-card-text class="text-center">
          <!-- <v-avatar size="70" class="mb-2">
            <img src="@/assets/images/optimy-logo.png" alt="" />
          </v-avatar> -->
           <h3 class=" font-weight-bold mb-4 mt-2">
            {{ $t("forgotPasswordPage.resetPassword") }}
          </h3>
          <h6 class="text--disabled font-weight-medium mb-10">
            {{ $t("forgotPasswordPage.sendMail") }}
          </h6>
          <v-form>
            <v-alert v-model="snackbar" dense :color="color" dark>
              {{status}}
            </v-alert>
            <v-text-field
              :label="$t('signInPage.email')"
              v-model="email"
              :error-messages="emailErrors"
              required
              @input="$v.email.$touch()"
              @blur="$v.email.$touch()"
            />
            <v-btn class="mb-4 mt-3" :loading="loading" @click="sendMail()" block color="primary" dark>
              <v-icon left>mdi-send</v-icon>
              {{ $t("forgotPasswordPage.send") }}</v-btn
            >
            <div class="d-flex justify-center flex-wrap">
              <v-btn text small color="primary" to='/' class="mb-2"
                >{{ $t("forgotPasswordPage.back") }}</v-btn
              >
            </div>
          </v-form>
        </v-card-text>
      </base-card>
    </div>
  </div>
</template>
<script>
// import firebase from "firebase/app";
// import { mapGetters, mapActions } from "vuex";
import { BASEURL } from "@/api/baseurl";
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
export default {
  name: "login",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Reset Password",
  },

  mixins: [validationMixin],

  validations: {
    email: {
      required,
      email,
    },
  },
  data() {
    return {
      domain: BASEURL,
      status:"",
      snackbar: false,
      loading:false,
      email: "",
      color: ""
    };
  },
  computed: {
    // ...mapGetters(['loggedInUser', 'error'])
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push(this.$t('signInPage.validMail'));
      !this.$v.email.required && errors.push(this.$t('signInPage.emailRequire'));
      return errors;
    },
  },
  methods: {
    sendMail() {
      this.loading=true
      this.$v.$touch();
      if (!this.$v.$error) {
        const params = new URLSearchParams();
        params.append("sendResetEmail", "done");
        params.append("email", this.email);

        axios({
          method: "post",
          url: this.domain + "/email/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
                
              this.snackbar = true
              this.status = this.$t('forgotPasswordPage.successStatus')
              this.color = "success"
              this.loading =false
              setTimeout(() => {
                this.$router.push("login");
              }, 1000);
            }else if (response.data.status == "2") {
              this.snackbar = true
              this.status = this.$t('forgotPasswordPage.failStatus')
              this.color = "error"
              this.loading =false
            }else if (response.data.status == "3") {
              this.snackbar = true
              this.status = this.$t('forgotPasswordPage.noReapeatSendStatus')
              this.color = "warning"
              this.loading =false
            } 
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  watch: {
  },
};
</script>
<style lang="scss" scoped>
.page-wrap {
  // background-image:url('../../../assets/images/login-background.jpg');
  background-image: linear-gradient(to bottom, transparent, #837960),url('../../../assets/images/login-background.jpg');
  background-color: #242939 !important;
  background-size: cover;
  display: flex;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
.no-uppercase {
  text-transform: unset !important;
}
</style>
